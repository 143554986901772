import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, Movies, Pagination } from "../../components"

const MoviesPage = ({ data, pageContext }) => {
  const {
    allAirtable: { nodes: movies },
  } = data

  // console.log("poems.js data", data)
  // console.log("poems.js pagecontext", pageContext)

  return (
    <Wrapper>
      <Layout>
        <Movies title="1001 Film" movies={movies} page />
        <Pagination pageContext={pageContext} />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  ${"" /* background from Poems.js  */}
  background: var(--clr-light-3);
  padding-top: 5rem;
  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }
`

export const query = graphql`
  query MoviesQuery($skip: Int, $limit: Int) {
    allAirtable(
      filter: { table: { eq: "Movies" } }
      limit: $limit
      skip: $skip
      sort: { fields: data___movies_title__hungarian, order: ASC }
    ) {
      nodes {
        id
        recordId
        data {
          movies_title
          movies_title__original
          movies_title__hungarian
          movies_excerpt {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          movies_director
          movies_row
          movies_text {
            childMarkdownRemark {
              html
            }
          }
          movies_image {
            localFiles {
              childImageSharp {
                fluid {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default MoviesPage
